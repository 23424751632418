import { render, staticRenderFns } from "./Voucher.vue?vue&type=template&id=36a88ca3&scoped=true&"
import script from "./Voucher.vue?vue&type=script&lang=js&"
export * from "./Voucher.vue?vue&type=script&lang=js&"
import style0 from "./Voucher.vue?vue&type=style&index=0&id=36a88ca3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36a88ca3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36a88ca3')) {
      api.createRecord('36a88ca3', component.options)
    } else {
      api.reload('36a88ca3', component.options)
    }
    module.hot.accept("./Voucher.vue?vue&type=template&id=36a88ca3&scoped=true&", function () {
      api.rerender('36a88ca3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8OfpRPd7ElcBb/JS/comp/Voucher.vue"
export default component.exports